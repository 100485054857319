import Tabby from "tabbyjs";
import "@fortawesome/fontawesome-free/js/all.js";
import MicroModal from "micromodal";
var tabs = new Tabby("[data-tabs]");

const speakerBioContent = {
	fuller: {
		name: "Jeffrey P. Fuller",
		role: "Moderator",
		position: "Legal Analyst",
		firm: "Bloomberg Law",
		email: "jfuller@bloombergindustry.com",
		url: "www.bloombergindustry.com",
		description: `
            <p>Jeffrey P. Fuller is a legal analyst with Bloomberg Law in Arlington, Va., on its Corporate Team, where he focuses on
            bankruptcy and financial restructuring. He previously worked for more than a decade of work as a chapter 11 attorney, representing debtors, secured creditors, creditors' committees, trustees, franchisees, landlords and others. Mr. Fuller received his B.A. from Davidson College, his M.A. from the University of Mississippi and his J.D. from the University of Tennessee College of Law.</p>
        `,
	},
	bologna: {
		name: "Katherine Bologna",
		role: "Asset-backed Securitization Lender",
		position: "Managing Director, ABS",
		firm: "Deutsche Bank AG",
		email: "katherine.bologna@db.com",
		url: "www.db.com",
		description: `
            <p>Katherine Bologna is a managing director in the Asset-backed Securitization team at Deutsche Bank in New York, where she focuses on rental car, auto finance and other consumer asset types. She has more than 17 years of structured finance experience and advises clients on all aspects of secured funding, including term securitizations, warehouse lending, M&A and advisory assignments. Ms. Bologna received her B.S. in economics from The Wharton School at the University of Pennsylvania.</p>
        `,
	},
	caton: {
		name: "Amy Caton",
		role: "Counsel to the Official Committee of Unsecured Creditors",
		position: "Partner",
		firm: "Kramer Levin Naftalis & Frankel LLP",
		email: "acaton@kramerlevin.com",
		url: "www.kramerlevin.com",
		description: `
            <p>Amy Caton is a partner with Kramer Levin Naftalis & Frankel LLP in New York and focuses on bankruptcy litigation and investigations; bondholder, lender, creditors' committee and other creditor representations; debtor and company-side representations, distressed mergers and acquisitions; and municipal and government insolvencies.</p>
            <p>She was named an American Lawyer Dealmaker of the Year for 2016 and 2017 for her work in the Commonwealth of Puerto Rico debt restructuring, and has represented clients in many of the nation’s largest and most complex bankruptcies over the past 20 years. Ms. Caton's recent high-profile restructurings include representing major lenders and investors in the restructuring of California's largest electrical utility company, PG&E; holders of more than $3 billion of revenue bonds in Puerto Rico Electric Power Authority's (PREPA) restructuring proceedings; OppenheimerFunds/Invesco in the Commonwealth of Puerto Rico’s restructurings; the majority holders of secured and unsecured debt in First Energy’s restructuring (now successfully completed); subordinated bondholders in California Agriculture’s restructurings; and the unsecured creditors' committee in Frontier Communications.</p>
            <p>She has been recognized as a leading lawyer in Chambers USA (2019-21), Legal 500 US (2010, 2012-20) and Lawdragon 500 (2019, 2020), and she was named an Outstanding Restructuring Lawyer by Turnaround & Workouts (2010, 2015, 2018) and was Am Law Litigation Daily’s Litigator of the Week (Feb. 12, 2015). She also was named a BTI Client Service All-Star (2019) and a Law360 Bankruptcy MVP (2015). Ms. Caton received her B.A., from the University of Texas at Austin, her Studies in E.U. Law, Universiteit Leiden (Leiden University), and her J.D. from the University of Texas School of Law.</p> 
        `,
	},
	derrough: {
		name: "William Q. Derrough",
		role: "Lead Investment Banker",
		position: "Co-head Restructuring and Recapitalization and member, Senior Leadership Team",
		firm: "Moelis & Company",
		email: "william.derrough@moelis.com",
		url: "www.moelis.com",
		description: `
            <p>William Q. Derrough is a managing director and global co-head of the Recapitalization and Restructuring Group of Moelis & Company in New York. He has experience advising all types of parties in major restructurings, including companies, boards of directors, creditor groups, shareholders, unions and governmental entities, and has
            been instrumental in building out the firm’s restructuring franchise.</p>
            <p>Under his leadership, the firm's R&R team, which has advised on more than 400 assignments globally representing over $1 trillion in value, was ranked #1 Restructuring Advisor both Globally and in the U.S. for 2019 completed engagements and was awarded the prestigious Restructuring Bank of the Year award for 2019 by IFR Magazine. Mr. Derrough has 30 years of investment banking experience. From 1998 until joining Moelis & Co., he co-founded and co-headed the Recapitalization & Restructuring Group at Jefferies & Co. While at Jefferies, he advised a wide spectrum of clients and investors on restructurings, recapitalizations, financings, mergers, and other engagements. Prior to joining Jefferies, Mr. Derrough was a principal at Doyle & Boissiere, a private investment firm. He began his investment banking career at Salomon Brothers Inc. Mr. Derrough received his B.A. from the University of California, Berkeley.</p>
        `,
	},
	lauria: {
		name: "Thomas E. Lauria",
		role: "Counsel to Hertz",
		position: "Partner and Global Head, Financial Restructuring and Insolvency Practice",
		firm: "White & Case",
		email: "tlauria@whitecase.com",
		url: "www.whitecase.com",
		description: `
            <p>Thomas E. Lauria is a partner and global head of White & Case LLP's Financial Restructuring and Insolvency Practice in New York and Miami, and is widely recognized as one of the leading bankruptcy attorneys in the U.S. He has played a pivotal role in the restructuring of more than $100 billion of debt in some of the largest and most complex restructurings in history.</p>
            <p>Mr. Lauria has more than 30 years of experience in complex insolvency matters. Among his numerous high-profile and award-winning deals, he advised the investment group that bought the Texas Rangers baseball team from bankruptcy, acted as lead counsel to the creditors who won ownership of Six Flags Inc. following the company's bankruptcy, and represented a group of creditors who opposed the U.S. government's § 363 sale of Chrysler. Mr. Lauria was named in the Financial Times in its 2010 list of "US Innovative Lawyers" for his work on these matters, and also led the restructuring of the Central European Distribution Corp., a deal that won the "Chapter 11 Reorganization of the Year (over US$1 billion)" award at the 2014 M&A Advisor Distressed Investing Summit, where he also won The M&A Advisor's 2014 Leadership Award.</p>
            <p>He is a frequent speaker and author on such topics as comparative insolvency law systems, enterprise valuation, cross-border restructuring, liquidating plans and alternative dispute resolution procedures. Mr. Lauria received his B.A. from the University of Tennessee and his J.D. from the University of Tennessee's George C. Taylor Law Center.</p>
        `,
	},
};

const speakerModalName = document.querySelector(".modal .speaker__name");
const speakerModalImg = document.querySelector(".modal .speaker__img");
const speakerModalPosition = document.querySelector(".modal .speaker__position");
const speakerModalRole = document.querySelector(".modal .speaker__role");
const speakerModalFirm = document.querySelector(".modal .speaker__firm");
const speakerModalDescription = document.querySelector(".modal .speaker__description");

const getSpeakerContent = (speaker, event) => {
	const speakerID = event.getAttribute("data-speaker");
	const { name, role, position, firm, email, description } = speakerBioContent[speakerID];

	speakerModalName.innerHTML = name;
	speakerModalImg.setAttribute("src", `https://abi-org.s3.amazonaws.com/Events/Hertz/${speakerID}.png`);
	speakerModalImg.setAttribute("alt", name);
	speakerModalPosition.innerHTML = position;
	speakerModalRole.innerHTML = role;
	speakerModalFirm.innerHTML = firm;
	speakerModalDescription.innerHTML = description;
};

MicroModal.init({
	onShow: getSpeakerContent,
	openClass: "is-open",
	disableScroll: true,
	disableFocus: false,
	awaitOpenAnimation: false,
	awaitCloseAnimation: false,
	debugMode: true,
});
